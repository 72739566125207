
export const DIMENSION = {
  Desktop: "desktop",
  Tablet: "tablet",
  Mobile: "mobile",
};

export const LAYOUT = {
  Fluid: "fluid",
  Boxed: "boxed",
};

export const socialIds = {
  1: "Twitter",
  2: "Facebook",
  3: "LinkedIn",
};

export const postStatus = {
  1: ["Success", "text-success"],
  3: ["Failed", "text-danger"],
  2: ["Pending", "text-warning"],
};

export const RADIUS = {
  Rounded: "rounded",
  Standard: "standard",
  Flat: "flat",
};

export const THEME_COLOR = {
  LightBlue: "light-blue",
  DarkBlue: "dark-blue",
  LightRed: "light-red",
  DarkRed: "dark-red",
  LightGreen: "light-green",
  DarkGreen: "dark-green",
  LightPurple: "light-purple",
  DarkPurple: "dark-purple",
  LightPink: "light-pink",
  DarkPink: "dark-pink",
};

export const NAV_COLOR = {
  Default: "default",
  Light: "light",
  Dark: "dark",
};
export const MENU_PLACEMENT = {
  Vertical: "vertical",
  Horizontal: "horizontal",
};
export const MENU_BEHAVIOUR = {
  Pinned: "pinned",
  Unpinned: "unpinned",
};

export const USER_ROLE = {
  Admin: "admin",
  Editor: "editor",
};

export const API_STATUS = Object.freeze({
  PENDING: "Pending",
  REJECTED: "Rejected",
  FULFILLED: "Fulfilled",
  NETWORK_ERROR: "Network Error",
});
export const JOBSTATUS = Object.freeze({
  0: "In active",
  1: "Hold",
  2: "Approve",
  3: "Removed",
});

export const COURSE_STATUS = Object.freeze({
  0: "Start",
  1: "In-Progress",
  2: "Completed",
});

export const EODSTATUS = Object.freeze({
  1: "Completed",
  2: "In-Progress",
  3: "Denied",
  4: "APPROVED",
});

export const STUDENT_ACCEPTANCE_STATUS = Object.freeze({
  0: "Not Yet Accepted",
  1: "Accepted",
  2: "Rejected",
  3: "Assigned to Mentor",
});

export const TYPE_OF_WORK = Object.freeze({
  1: "Coding",
  2: "Research",
  3: "Learning",
  4: "Project Implementation",
  5: "Meeting",
  6: "Mentor Discussion",
  7: "Team Meeting",
  8: "Execution",
});

export const FEEDBACK_STATUS = Object.freeze({
  1: "Accepted",
  2: "Rejected",
});

export const ADMIN_ACCEPTANCE_STATUS = Object.freeze({
  0: "Not yet attended",
  1: "Attended and waiting for result",
  2: "Approved and Yet to Assign CC Mentor",
  3: "Mentor Assigned",
});

export const ADMIN_ACCEPTANCE_COLOR = Object.freeze({
  0: "danger",
  1: "warning",
  2: "primary",
  3: "success",
});

export const TYPE_OF_ACTIVITY = Object.freeze({
  1: "Technical",
  2: "Non-Technical",
});

export const JOB_STATUS = Object.freeze({
  0: "Inactive",
  1: "Hold",
  2: "Approved",
  3: "Removed",
});

export const QUESTION_TYPE = Object.freeze({
  1: "Textarea",
  2: "Text",
  3: "Select",
  4: "Radio",
  5: "Checkbox",
});

export const QUESTION_GROUP = Object.freeze({
  1: "First",
  2: "Second",
});

export function getKeyByValue(object, value) {
  for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
          if (object[prop] === value)
              return prop;
      }
  }
}


export const excludedMessages = ["Current Group Updated", ""];

export const statusData = Object.freeze({
  0: "ORDER INITIATED",
  1: "ONHOLD",
  2: "DISPATCHED",
  3: "DELIVERY IN PROCESS",
  4: "DELIVERED",
  5: "COMPLETED",
  6: "PAID",
});


export const  orderStatusData = Object.freeze({
  0: "ATTEMPTED",
  1: "PENDING",
  2: "CONFIRMED",
  3: "FAILED",
  4: "REJECTED",
});
export const selectDefault = "ufybwcuf&&";
export const PAY_METHOD = Object.freeze({
  ONLINE: 1,
  COD: 0
})
export const supportStuts = Object.freeze({
  0: "INITIATED",
  1: "ACCEPTED",
  2: "RESLOVE",
 
});


export const statusClasses = {
  0: "alert-info",      
  1: "alert-secondary", 
  2: "alert-primary",   
  3: "alert-warning", 
  4: "alert-success",   
  5: "alert-success",   
  6: "alert-success"    
};

