export const UserActionTypes = {
  REGISTER_USER_REQUEST: "@@user/REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "@@user/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "@@user/REGISTER_USER_FAILURE",

  LOGIN_USER_REQUEST: "@@user/LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "@@user/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "@@user/LOGIN_USER_FAILURE",

  USER_LOGOUT: "@@user/USER_LOGOUT",
  USERDETAIL_RESET: "@@userdetail/USERDETAIL_RESET",
  LOGINSUCCESS_RESET: "@@user/LOGINSUCCESS_RESET",

  GETUSERDATA_REQUEST: "@@user/GETUSERDATA_REQUEST",
  GETUSERDATA_SUCCESS: "@@user/GETUSERDATA_SUCCESS",
  GETUSERDATA_FAILURE: "@@user/GETUSERDATA_FAILURE",

  UPDATE_USER_REQUEST: "@@user/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "@@user/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "@@user/UPDATE_USER_FAILURE",

};

export const userInitialState = {
  user_email: null,
  user: [],
  isUserRegisterLoading: false,
  registerSuccess:false,
  isUserLoginLoading: false,
  isUserDetailLoading: false,
  loginSuccess: false,
  userDataLoading:false,
  userData:[],
  updateUserLoad:false,
  updateSuccess:false,
};

export const userSelector = (state) => state.UserReducer;
