import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import img1 from "../../assets/imgs/page/account/img-1.png";
import img2 from "../../assets/imgs/page/account/img-2.png";
import img3 from "../../assets/imgs/page/account/img-3.png";
import img4 from "../../assets/imgs/page/account/img-4.png";
import imgsub from "../../assets/imgs/page/product/img-sub.png";
import star from "../../assets/imgs/template/icons/star.svg";
import stargray from "../../assets/imgs/template/icons/star-gray.svg";
import imgsub2 from "../../assets/imgs/page/product/img-sub2.png";
import imgsub3 from "../../assets/imgs/page/product/img-sub3.png";
import imgsub4 from "../../assets/imgs/page/product/img-sub4.png";
import imgsub5 from "../../assets/imgs/page/product/img-sub5.png";
import gallery1 from "../../assets/imgs/page/product/img-gallery-1.jpg";
import gallery2 from "../../assets/imgs/page/product/img-gallery-2.jpg";
import gallery3 from "../../assets/imgs/page/product/img-gallery-3.jpg";
import gallery4 from "../../assets/imgs/page/product/img-gallery-4.jpg";
import gallery5 from "../../assets/imgs/page/product/img-gallery-5.jpg";
import gallery6 from "../../assets/imgs/page/product/img-gallery-6.jpg";
import gallery7 from "../../assets/imgs/page/product/img-gallery-7.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { cartGetRequest, getOrderDetailRequest } from "../../store/cart/cart.action";
import { cartSelector } from "../../store/cart/cart.model";
import dayjs from "dayjs";
import { API_BASE } from "../../services/config";
import noImg from "../../assets/imgs/no-image.jpg";
import { statusClasses, statusData } from "../../utils/Constants";
import { accSelector } from "../../store/account/account.model";
import { accGetRequest } from "../../store/account/account.action";
function Myaccount() {
  const dispatch = useDispatch();
  const { orderDetailData } = useSelector(cartSelector);
  const { AccData } = useSelector(accSelector);
  console.log(orderDetailData, "567jhfks");
  /* State */
  /* Function */
  /* useEfffect */
  useEffect(() => {
    dispatch(getOrderDetailRequest());
    dispatch(accGetRequest())
  }, []);
  console.log(AccData,"12345");
  
  return (
    <>
      <Header />
      <Sidebar />
      <section class="section-box shop-template mt-30">
        <Container class="box-account-template">
          <h3>
            Hello {orderDetailData?.length > 0 && orderDetailData[0].user?.name}
          </h3>
          <p class="font-md color-gray-500">
            From your account dashboard, you can easily check and view your
            recent orders.
            <br class="d-none d-lg-block" />
            Manage your shipping addresses to keep your orders delivered
            smoothly and on time.
          </p>
          <div className="row mt-10">
            <div className="col-md-3">
              <div class="order_cards">
                <p class="font-base color-gray-900 mb-10">Total Credit Amount</p>
                <h5 class="mb-1 color-gray-900">{AccData?.credit_amount}</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div class="order_cards">
                <p class="font-base color-gray-900 mb-10">Available Credit Amount</p>
                <h5 class="mb-1 color-gray-900">{AccData?.available_credit}</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div class="order_cards">
                <p class="font-base color-gray-900 mb-10">Total Invoiced Amount</p>
                <h5 class="mb-1 color-gray-900">{AccData?.total_invoice}</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div class="order_cards">
                <p class="font-base color-gray-900 mb-10">Total Amount Paid</p>
                <h5 class="mb-1 color-gray-900">{AccData?.total_payment}</h5>
              </div>
            </div>
            <div className="col-md-3 mt-10">
              <div class="order_cards">
                <p class="font-base color-gray-900 mb-10">Balance Invoice Amount</p>
                <h5 class="mb-1 color-gray-900">{AccData?.invoice_bal}</h5>
              </div>
            </div>
          </div>
          <div class="box-tabs mb-100">
            <ul class="nav nav-tabs nav-tabs-account" role="tablist">
              {/* <li><a class="active" href="#tab-notification" data-bs-toggle="tab" role="tab" aria-controls="tab-notification" aria-selected="true">Notification</a></li>
              <li><a href="#tab-wishlist" data-bs-toggle="tab" role="tab" aria-controls="tab-wishlist" aria-selected="true">Wishlist</a></li> */}
              <li>
                <a
                  href="#tab-orders"
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls="tab-orders"
                  aria-selected="true"
                >
                  Orders
                </a>
              </li>
              {/* <li><a href="#tab-order-tracking" data-bs-toggle="tab" role="tab" aria-controls="tab-order-tracking" aria-selected="true">Order Tracking</a></li> */}
              {/* <li>
                <a
                  href="#tab-setting"
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls="tab-setting"
                  aria-selected="true"
                >
                  Setting
                </a>
              </li> */}
            </ul>
            <div class="border-bottom mt-20 mb-40"></div>
            <div class="tab-content mt-30">
              <div
                class="tab-pane fade active show"
                id="tab-orders"
                role="tabpanel"
                aria-labelledby="tab-orders"
              >
                <div class="box-orders">
                  {orderDetailData?.map((ele, index) => {
                    return (
                      <div>
                        <div class="head-orders">
                          <div class="head-left">
                            <h5 class="mr-20">Order ID: {ele?.id}</h5>
                            <span class="font-md color-brand-3 mr-20">
                              Date:{" "}
                              {dayjs(ele?.created_at).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div class="head-right">
                            {/* <a class="btn btn-buy font-sm-bold w-auto">
                              View Order
                            </a> */}
                            <span
                              className={`badge rounded-pill labelstyle  ${
                                statusClasses[ele?.status] || "alert-secondary "
                              }`}
                            >
                              {statusData[ele?.status]}
                            </span>
                          </div>
                        </div>
                        <div class="body-orders">
                          <div class="list-orders">
                            {ele?.orderdetails?.map((ele1, index) => {
                              return (
                                <div class="item-orders">
                                  <div class="image-orders">
                                    <img
                                      src={
                                        ele1?.products?.image
                                          ? API_BASE +
                                            "products/" +
                                            ele1?.products?.image
                                          : noImg
                                      }
                                      alt="Ecom"
                                    />
                                  </div>
                                  <div class="info-orders">
                                    <h5>{ele1?.products?.name}</h5>
                                  </div>
                                  <div class="quantity-orders">
                                    <h5>Quantity: {ele1?.quantity}</h5>
                                  </div>
                                  <div class="price-orders">
                                    <h4>
                                      ₹{" "}
                                      {parseFloat(ele1?.sale_price).toFixed(2)}
                                    </h4>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <nav className="d-none">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link page-prev" href="#"></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link " href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        4
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        5
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        6
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link page-next" href="#"></a>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* <div class="tab-pane fade" id="tab-order-tracking" role="tabpanel" aria-labelledby="tab-order-tracking">
                <p class="font-md color-gray-600">To track your order please enter your OrderID in the box below and press "Track" button. This was given to you on<br class="d-none d-lg-block"/>your receipt and in the confirmation email you should have received.</p>
                <div class="row mt-30">
                  <div class="col-lg-6">
                    <div class="form-tracking">
                      <form action="#" method="get">
                        <div class="d-flex">
                          <div class="form-group box-input">
                            <input class="form-control" type="text" placeholder="FDSFWRFAF13585"/>
                          </div>
                          <div class="form-group box-button">
                            <button class="btn btn-buy font-md-bold" type="submit">Tracking Now</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="border-bottom mb-20 mt-20"></div>
                <h3 class="mb-10">Order Status:<span class="color-success">International shipping</span></h3>
                <h6 class="color-gray-500">Estimated Delivery Date: 27 August - 29 August</h6>
                <div class="table-responsive">
                  <div class="list-steps">
                    <div class="item-step">
                      <div class="rounded-step">
                        <div class="icon-step step-1 active"></div>
                        <h6 class="mb-5">Order Placed</h6>
                        <p class="font-md color-gray-500">15 August 2022</p>
                      </div>
                    </div>
                    <div class="item-step">
                      <div class="rounded-step">
                        <div class="icon-step step-2 active"></div>
                        <h6 class="mb-5">In Producttion</h6>
                        <p class="font-md color-gray-500">16 August 2022</p>
                      </div>
                    </div>
                    <div class="item-step">
                      <div class="rounded-step">
                        <div class="icon-step step-3 active"></div>
                        <h6 class="mb-5">International shipping</h6>
                        <p class="font-md color-gray-500">17 August 2022</p>
                      </div>
                    </div>
                    <div class="item-step">
                      <div class="rounded-step">
                        <div class="icon-step step-4"></div>
                        <h6 class="mb-5">Shipping Final Mile</h6>
                        <p class="font-md color-gray-500">18 August 2022</p>
                      </div>
                    </div>
                    <div class="item-step">
                      <div class="rounded-step">
                        <div class="icon-step step-5"></div>
                        <h6 class="mb-5">Delivered</h6>
                        <p class="font-md color-gray-500">19 August 2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-features">
                  <ul>
                    <li>09:10 25 August 2022: Delivery in progress</li>
                    <li>08:25 25 August 2022: The order has arrived at warehouse 05-YBI Marvel LM Hub</li>
                    <li>05:44 25 August 2022: Order has been shipped</li>
                    <li>04:51 25 August 2022: The order has arrived at Marvel SOC warehouse</li>
                    <li>20:54 18 August 2022: Order has been shipped</li>
                    <li>18:21 17 August 2022: The order has arrived at Marvel SOC warehouse</li>
                    <li>17:09 17 August 2022: Order has been shipped</li>
                    <li>15:23 17 August 2022: The order has arrived at warehouse 20-HNI Marvel 2 SOC</li>
                    <li>12:42 16 August 2022: Successful pick up</li>
                    <li>10:44 15 August 2022: The sender is preparing the goods</li>
                  </ul>
                </div>
                <h3>Package Location</h3>
                <div class="map-account">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193548.25784139088!2d-74.12251055507726!3d40.71380001554004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2zVGjDoG5oIHBo4buRIE5ldyBZb3JrLCBUaeG7g3UgYmFuZyBOZXcgWW9yaywgSG9hIEvhu7M!5e0!3m2!1svi!2s!4v1664974174994!5m2!1svi!2s"></iframe>
                </div>
                <Row>
                  <Col lg={6}>
                    <p class="color-gray-500 mb-20">Maecenas porttitor augue sit amet nibh venenatis bibendum. Morbi lorem elit, fringilla quis libero vitae, tincidunt commodo purus. Quisque diam nisi, tincidunt sed vehicula nec, fermentum vitae lectus. Curabitur sit amet sagittis libero. Pellentesque cursus turpis at ipsum luctus tempor.</p>
                  </Col>
                  <Col lg={6}>
                    <p class="color-gray-500 mb-20">Ut auctor varius nisl, scelerisque dictum justo maximus ut. Fusce rhoncus, augue sed molestie consectetur, leo felis ultricies erat, nec lobortis enim dui eu justo. Pellentesque aliquam hendrerit venenatis. Integer efficitur bibendum lectus sed sollicitudin. Suspendisse faucibus posuere euismod.</p>
                  </Col>
                </Row>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
      <section class="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6}>
              <h3 class="color-white">
                Subscrible &amp; Get <span class="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p class="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span class="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div class="box-form-newsletter mt-15">
                <form class="form-newsletter">
                  <input
                    class="input-newsletter font-xs"
                    value=""
                    placeholder="Your email Address"
                  />
                  <button class="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div
        class="modal fade"
        id="ModalFiltersForm"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content apply-job-form">
            <div class="modal-header">
              <h5 class="modal-title color-gray-1000 filters-icon">
                Addvance Fillters
              </h5>
              <button
                class="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-30">
              <Row>
                <div class="col-w-1">
                  <h6 class="color-gray-900 mb-0">Brands</h6>
                  <ul class="list-checkbox">
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span class="text-small">Apple</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Samsung</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Baseus</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Remax</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Handtown</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Elecom</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Razer</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Auto Focus</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Nillkin</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Logitech</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">ChromeBook</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="col-w-1">
                  <h6 class="color-gray-900 mb-0">Special offers</h6>
                  <ul class="list-checkbox">
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">On sale</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span class="text-small">FREE shipping</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Big deals</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Shop Mall</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 class="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
                  <ul class="list-checkbox">
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">1 business day</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span class="text-small">1&ndash;3 business days</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">in 1 week</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Shipping now</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="col-w-1">
                  <h6 class="color-gray-900 mb-0">Ordering options</h6>
                  <ul class="list-checkbox">
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Accepts gift cards</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Customizable</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span class="text-small">Can be gift-wrapped</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Installment 0%</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 class="color-gray-900 mb-0 mt-40">Rating</h6>
                  <ul className="list-checkbox">
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (5 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (4 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (3 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (2 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (1 star)
                        </span>
                      </a>
                    </li>
                  </ul>
                  <ul className="list-checkbox">
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (5 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (4 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (3 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (2 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (1 star)
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-w-2">
                  <h6 class="color-gray-900 mb-0">Material</h6>
                  <ul class="list-checkbox">
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Nylon (8)</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Tempered Glass (5)</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span class="text-small">
                          Liquid Silicone Rubber (5)
                        </span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="cb-container">
                        <input type="checkbox" />
                        <span class="text-small">Aluminium Alloy (3)</span>
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 class="color-gray-900 mb-20 mt-40">Product tags</h6>
                  <div>
                    <a class="btn btn-border mr-5" href="#">
                      Games
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      Electronics
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      Video
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      Cellphone
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      Indoor
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      VGA Card
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      USB
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      Lightning
                    </a>
                    <a class="btn btn-border mr-5" href="#">
                      Camera
                    </a>
                  </div>
                </div>
              </Row>
            </div>
            <div class="modal-footer justify-content-start pl-30">
              <a class="btn btn-buy w-auto" href="#">
                Apply Fillter
              </a>
              <a class="btn font-sm-bold color-gray-500" href="#">
                Reset Fillter
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="ModalQuickview"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content apply-job-form">
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body p-30">
              <Row>
                <Col lg={6}>
                  <div class="gallery-image">
                    <div class="galleries-2">
                      <div class="detail-gallery">
                        <div class="product-image-slider-2">
                          <figure class="border-radius-10">
                            <img src={gallery1} alt="product image" />
                          </figure>
                          <figure class="border-radius-10">
                            <img src={gallery2} alt="product image" />
                          </figure>
                          <figure class="border-radius-10">
                            <img src={gallery3} alt="product image" />
                          </figure>
                          <figure class="border-radius-10">
                            <img src={gallery4} alt="product image" />
                          </figure>
                          <figure class="border-radius-10">
                            <img src={gallery5} alt="product image" />
                          </figure>
                          <figure class="border-radius-10">
                            <img src={gallery6} alt="product image" />
                          </figure>
                          <figure class="border-radius-10">
                            <img src={gallery7} alt="product image" />
                          </figure>
                        </div>
                      </div>
                      <div class="slider-nav-thumbnails-2">
                        <div>
                          <div class="item-thumb">
                            <img src={gallery1} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div class="item-thumb">
                            <img src={gallery2} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div class="item-thumb">
                            <img src={gallery3} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div class="item-thumb">
                            <img src={gallery4} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div class="item-thumb">
                            <img src={gallery5} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div class="item-thumb">
                            <img src={gallery6} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div class="item-thumb">
                            <img src={gallery7} alt="product image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box-tags">
                    <div class="d-inline-block mr-25">
                      <span class="font-sm font-medium color-gray-900">
                        Category:
                      </span>
                      <a class="link" href="#">
                        Smartphones
                      </a>
                    </div>
                    <div class="d-inline-block">
                      <span class="font-sm font-medium color-gray-900">
                        Tags:
                      </span>
                      <a class="link" href="#">
                        Blue
                      </a>
                      ,
                      <a class="link" href="#">
                        Smartphone
                      </a>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="product-info">
                    <h5 class="mb-15">
                      SAMSUNG Galaxy S22 Ultra, 8K Camera & Video, Brightest
                      Display Screen, S Pen Pro
                    </h5>
                    <div class="info-by">
                      <span class="bytext color-gray-500 font-xs font-medium">
                        by
                      </span>
                      <a
                        class="byAUthor color-gray-900 font-xs font-medium"
                        href="shop-vendor-list.html"
                      >
                        {" "}
                        Ecom Tech
                      </a>
                      <div class="rating d-inline-block">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <span class="font-xs color-gray-500 font-medium">
                          {" "}
                          (65 reviews)
                        </span>
                      </div>
                    </div>
                    <div class="border-bottom pt-10 mb-20"></div>
                    <div class="box-product-price">
                      <h3 class="color-brand-3 price-main d-inline-block mr-10">
                        $2856.3
                      </h3>
                      <span class="color-gray-500 price-line font-xl line-througt">
                        $3225.6
                      </span>
                    </div>
                    <div class="product-description mt-10 color-gray-900">
                      <ul class="list-dot">
                        <li>8k super steady video</li>
                        <li>Nightography plus portait mode</li>
                        <li>50mp photo resolution plus bright display</li>
                        <li>Adaptive color contrast</li>
                        <li>premium design & craftmanship</li>
                        <li>Long lasting battery plus fast charging</li>
                      </ul>
                    </div>
                    <div class="box-product-color mt-10">
                      <p class="font-sm color-gray-900">
                        Color:
                        <span class="color-brand-2 nameColor">Pink Gold</span>
                      </p>
                      <ul class="list-colors">
                        <li class="disabled">
                          <img src={gallery1} alt="Ecom" title="Pink" />
                        </li>
                        <li>
                          <img src={gallery2} alt="Ecom" title="Gold" />
                        </li>
                        <li>
                          <img src={gallery3} alt="Ecom" title="Pink Gold" />
                        </li>
                        <li>
                          <img src={gallery4} alt="Ecom" title="Silver" />
                        </li>
                        <li class="active">
                          <img src={gallery5} alt="Ecom" title="Pink Gold" />
                        </li>
                        <li class="disabled">
                          <img src={gallery6} alt="Ecom" title="Black" />
                        </li>
                        <li class="disabled">
                          <img src={gallery7} alt="Ecom" title="Red" />
                        </li>
                      </ul>
                    </div>
                    <div class="box-product-style-size mt-10">
                      <Row>
                        <Col lg={12} class="mb-10">
                          <p class="font-sm color-gray-900">
                            Style:
                            <span class="color-brand-2 nameStyle">S22</span>
                          </p>
                          <ul class="list-styles">
                            <li class="disabled" title="S22 Ultra">
                              S22 Ultra
                            </li>
                            <li class="active" title="S22">
                              S22
                            </li>
                            <li title="S22 + Standing Cover">
                              S22 + Standing Cover
                            </li>
                          </ul>
                        </Col>
                        <Col lg={12} class="mb-10">
                          <p class="font-sm color-gray-900">
                            Size:
                            <span class="color-brand-2 nameSize">512GB</span>
                          </p>
                          <ul class="list-sizes">
                            <li class="disabled" title="1GB">
                              1GB
                            </li>
                            <li class="active" title="512 GB">
                              512 GB
                            </li>
                            <li title="256 GB">256 GB</li>
                            <li title="128 GB">128 GB</li>
                            <li class="disabled" title="64GB">
                              64GB
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div class="buy-product mt-5">
                      <p class="font-sm mb-10">Quantity</p>
                      <div class="box-quantity">
                        <div class="input-quantity">
                          <input
                            class="font-xl color-brand-3"
                            type="text"
                            value="1"
                          />
                          <span class="minus-cart"></span>
                          <span class="plus-cart"></span>
                        </div>
                        <div class="button-buy">
                          <a class="btn btn-cart" href="shop-cart.html">
                            Add to cart
                          </a>
                          <a class="btn btn-buy" href="shop-checkout.html">
                            Buy now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}
export default Myaccount;
