export const createProductPayload = (
    page, 
    limit, 
    query = "", 
    catId = "", 
    brandId = "", 
    sortBy = "category_id", 
    order = "asc"
  ) => ({
    page,
    limit,
    query,
    cat_id: catId,
    brand_id: brandId,
    sortBy,
    order,
  });
  
  export const createCartPayload = (selectedProduct, quantity) => [
    {
      product_id: selectedProduct.id,
      quantity,
      category_id: selectedProduct.catgory_id,
      reg_price: selectedProduct.msrp,
      sale_price: selectedProduct.w_rate,
    },
  ];
  