import logo2 from "../../src/assets/imgs/template/logo-2.svg";
import appstore from "../../src/assets/imgs/template/appstore.png";
import google from "../../src/assets/imgs/template/google-play.png";
import pay from "../../src/assets/imgs/template/payment-method.png";
import { Container, Row, Col } from "react-bootstrap";

function Footer(){
    return(
        <>
    <footer className="footer">
      <div className="footer-1">
        <Container>
          <Row>
            <Col md={3} className="mb-30">
              <h4 className="mb-30 color-gray-1000">Contact</h4>
              <div className="font-md mb-20 color-gray-900"><strong className="font-md-bold">Address:</strong> 87/1, Mahalakshmi Nagar, Lakshmipuram, Masakkalipalayam, Coimbatore, Tamil Nadu 641028.</div>
              <div className="font-md mb-20 color-gray-900"><strong className="font-md-bold">Phone:</strong> (+91) 80483 72505</div>
              <div className="font-md mb-20 color-gray-900"><strong className="font-md-bold">E-mail:</strong> info@sikanpvcpipesbend.com</div>
              <div className="font-md mb-20 color-gray-900"><strong className="font-md-bold">Hours:</strong> 8:00 - 17:00, Mon - Sat</div>
              <div className="mt-30"><a className="icon-socials icon-facebook" href="#"></a><a className="icon-socials icon-instagram" href="#"></a><a className="icon-socials icon-twitter" href="#"></a><a className="icon-socials icon-linkedin" href="#"></a></div>
            </Col>
            <Col md={3} className="mb-30">
              <h4 className="mb-30 color-gray-1000">Company</h4>
              <ul className="menu-footer">
                <li><a href="blog-2.html">Our Blog</a></li>
                <li><a href="#">Plans &amp; Pricing</a></li>
                <li><a href="#">Knowledge Base</a></li>
                <li><a href="#">Cookie Policy</a></li>
                <li><a href="#">Office Center</a></li>
                <li><a href="blog.html">News &amp; Events</a></li>
              </ul>
            </Col>
            <Col md={3} className="mb-30">
              <h4 className="mb-30 color-gray-1000">My account</h4>
              <ul className="menu-footer">
                <li><a href="#">FAQs</a></li>
                <li><a href="#">Editor Help</a></li>
                <li><a href="#">Community</a></li>
                <li><a href="#">Live Chatting</a></li>
                <li><a href="#">Contact Us</a></li>
                <li><a href="#">Support Center</a></li>
              </ul>
            </Col>
            <Col md={3} className="mb-30">
              <h4 className="mb-30 color-gray-1000">App &amp; Payment</h4>
              <div>
                <p className="font-md color-gray-900">Download our Apps and get extra 15% Discount on your first Order&mldr;!</p>
                <div className="mt-20"><a className="mr-10" href="#"><img src={appstore} alt="Ecom"/></a><a href="#"><img src={google} alt="Ecom"/></a></div>
                <p className="font-md color-gray-900 mt-20 mb-10">Secured Payment Gateways</p><img src={pay} alt="Ecom"/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
      <div className="footer-2">
        <Container>
          <div className="footer-bottom">
            <Row>
              <Col md={6} className="text-center text-lg-start">
                <span className="color-gray-900 font-sm">Copyright &copy; 2024 SIKAN PVC Pipes & Bend. All rights reserved.</span>
              </Col>

              <Col md={6} className="text-center text-lg-end">
                <ul className="menu-bottom">
                  <li><a className="font-sm color-gray-900" href="page-term.html">Conditions of Use</a></li>
                  <li><a className="font-sm color-gray-900" href="page-term.html">Privacy Notice</a></li>
                  <li><a className="font-sm color-gray-900" href="page-careers.html">Interest-Based Ads</a></li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
        </>
    );
}
export default Footer;