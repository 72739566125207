
import { decryptdata } from "../../utils/encrypt&decrypt";
import { UserActionTypes, userInitialState } from "./user.model";
const reducer = (state = userInitialState, action) => {
  console.log(action.payload, "SADFFASD");
  console.log(userInitialState, "asdfg");
  switch (action.type) {
    case UserActionTypes.REGISTER_USER_REQUEST:
      return {
        ...state,
        isUserRegisterLoading: true,
        registerSuccess: false,
      };

    case UserActionTypes.REGISTER_USER_SUCCESS:
      console.log(action.payload.data.email, 4958);
      return {
        ...state,
        user_email: action.payload.data.email,
        isUserRegisterLoading: false,
        registerSuccess: true,
      };

    case UserActionTypes.REGISTER_USER_FAILURE:
      return {
        isUserRegisterLoading: false,
        registerSuccess: false,
      };

    case UserActionTypes.LOGIN_USER_REQUEST:
      return {
        ...state,
        isUserLoginLoading: true,
        loginSuccess: false,
      };

    case UserActionTypes.LOGIN_USER_SUCCESS:
      localStorage.setItem(
        "authKey",
        action?.payload?.data?.datas?.accessToken
      );
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("email", action?.payload?.data?.datas?.email);
      localStorage.setItem("type", action?.payload?.data?.datas?.type);
      localStorage.setItem("role", action?.payload?.data?.datas?.role);
      localStorage.setItem("id", action?.payload?.data?.datas?.id);

      return {
        ...state,
        user: action.payload.data,
        isUserLoginLoading: false,
        loginSuccess: true,
      };

    case UserActionTypes.LOGIN_USER_FAILURE:
      return {
        email: null,
        isUserLoginLoading: false,
        loginSuccess: false,
      };

    case UserActionTypes.USERDETAIL_RESET:
      return {
        email: null,
        isUserDetailLoading: false,
      };

    case UserActionTypes.LOGINSUCCESS_RESET:
      return {
        loginSuccess: false,
        registerSuccess: false,
        user_email: null,
      };
      case UserActionTypes.GETUSERDATA_REQUEST:
      return {
        ...state,
        userDataLoading: true,
      };
    case UserActionTypes.GETUSERDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas2 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas2.data, "yhoio");
      return {
        ...state,
        userDataLoading: false,
        userData: payloaddatas2?.data,
      };
    case UserActionTypes.GETUSERDATA_FAILURE:
      return {
        ...state,
        userDataLoading: false,
      };
      case UserActionTypes.UPDATE_USER_REQUEST:
        return {
          ...state,
          updateUserLoad: true,
          updateSuccess: false,
        };
      case UserActionTypes.UPDATE_USER_SUCCESS:
        return {
          ...state,
          updateUserLoad: false,
          updateSuccess: true,
        };
      case UserActionTypes.UPDATE_USER_FAILURE:
        return {
          ...state,
          updateUserLoad: false,
          updateSuccess: false,
        };
        case UserActionTypes.USER_LOGOUT:
          return {
            ...state,
            updateUserLoad: false,
            updateSuccess: false,
          };
    default:
      return state;
  }
};

export { reducer as UserReducer };
