export const cartActionTypes = {
  CARTS_GET_REQUEST: "@cart/CARTS_GET_REQUEST",
  CARTS_GET_SUCCESS: "@cart/CARTS_GET_SUCCESS",
  CARTS_GET_FAILURE: "@cart/CARTS_GET_FAILURE",

  ADDTOCART_POST_REQUEST: "@cart/ADDTOCART_POST_REQUEST",
  ADDTOCART_POST_SUCCESS: "@cart/ADDTOCART_POST_SUCCESS",
  ADDTOCART_POST_FAILURE: "@cart/ADDTOCART_POST_FAILURE",

  CHECKOUT_POST_REQUEST: "@checkout/CHECKOUT_POST_REQUEST",
  CHECKOUT_POST_SUCCESS: "@checkout/CHECKOUT_POST_SUCCESS",
  CHECKOUT_POST_FAILURE: "@checkout/CHECKOUT_POST_FAILURE",

  ORDERDETAIL_GET_REQUEST: "@checkoutorder/ORDERDETAIL_GET_REQUEST",
  ORDERDETAIL_GET_SUCCESS: "@checkoutorder/ORDERDETAIL_GET_SUCCESS",
  ORDERDETAIL_GET_FAILURE: "@checkoutorder/ORDERDETAIL_GET_FAILURE",
  
  DISPLAYRAZORPAY_REQUEST: "@@checkout/DISPLAYRAZORPAY_REQUEST",
  DISPLAYRAZORPAY_SUCCESS: "@@checkout/DISPLAYRAZORPAY_SUCCESS",
  DISPLAYRAZORPAY_FAILURE: "@@checkout/DISPLAYRAZORPAY_FAILURE",
  

  MAKEPAYMENT_REQUEST: "@@checkout/MAKEPAYMENT_REQUEST",
  MAKEPAYMENT_SUCCESS: "@@checkout/MAKEPAYMENT_SUCCESS",
  MAKEPAYMENT_FAILURE: "@@checkout/MAKEPAYMENT_FAILURE",  

  STATE_RESET_REQUEST: "@@cart/STATE_RESET_REQUEST",
};
export const cartInitialState = {
  addCardLoad: false,
  succesMessage: "",
  cartloading: false,
  getCarts: [],
  checkOutLoad: false,
  ordergetLoad: false,
  orderDetailData: [],
  onlinecheckLoad:false,
  onlinecheckLoadSuccess:false,
  paymentLoad:false,
  paymentSuccess: null,
  error: null,

};
export const cartSelector = (state) => state.cartReducer;
