import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const addCardApi = (payload) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/cart/addCart",payload, Http.getAuthToken());
  };

  export const getCartApi = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl +
        "/cart/cartProduct?query=" +
        query.query +
        "&page=" +
        query.page +
        "&limit=" +
        query.limit,
      {},
      Http.getAuthToken()
    );
  };

  export const checkOutApi = (payload) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/checkout",payload, Http.getAuthToken());
  };
  export const getOrderDetialApi = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/orders/list", Http.getAuthToken());
  };
  export const onlineCheckOutApi = (payload) => {
    console.log(payload, "payloasdfdf")
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/checkout",payload, Http.getAuthToken());
  };
  export const failPayment = (data) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/checkout/fail", data, Http.getAuthToken());
}
export const verifyPayment = data => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/checkout/verification", data, Http.getAuthToken());
}
